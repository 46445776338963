<template>
  <div class="container">
    <div class="card" style="display: flex">
      <Card shadow style="flex: 1">
        <Row>
          <Col span="10">
            <img src="/imgs/statistics/jisandian.png" alt="" />
          </Col>
          <Col span="12" style="text-align: center">
            <div>
              <p style="color: #7d9cf2; font-size: 30px">{{ disposeNum }}</p>
              <p style="font-size: 18px; font-weight: 600">粪污处理点(个)</p>
            </div>
          </Col>
        </Row>
      </Card>
      <Card shadow style="flex: 1; margin: 0 20px">
        <Row>
          <Col span="10">
            <img src="/imgs/statistics/leixing.png" alt="" />
          </Col>
          <Col span="12" style="text-align: center">
            <div>
              <p style="color: #8ccdfa; font-size: 30px">{{ typeNum }}</p>
              <p style="font-size: 18px; font-weight: 600">设备类型(种)</p>
            </div>
          </Col>
        </Row>
      </Card>
      <Card shadow style="flex: 2">
        <Row>
          <Col span="6">
            <img src="/imgs/statistics/shebei.png" alt="" />
          </Col>
          <Col span="6" style="text-align: center">
            <div>
              <p style="color: #ad79f9; font-size: 30px">
                {{ facilityNum.deviceCount }}
              </p>
              <p style="font-size: 18px; font-weight: 600">施用设备(个)</p>
            </div>
          </Col>
          <Col span="6" style="text-align: center">
            <div>
              <p style="color: #1fba9a; font-size: 30px">
                {{ facilityNum.enableDeviceCount }}
              </p>
              <p style="font-size: 18px; font-weight: 600">在线(个)</p>
            </div>
          </Col>
          <Col span="6" style="text-align: center">
            <div>
              <p style="color: #fa5eea; font-size: 30px">
                {{ facilityNum.disableDeviceCount }}
              </p>
              <p style="font-size: 18px; font-weight: 600">离线(个)</p>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
    <div style="margin-top: 20px">
      <Row>
        <Col span="4">
          <Card>
            <div style="height: 280px">
              <p style="font-size: 18px; font-weight: 600">设备处理量</p>
              <p
                style="
                  text-align: center;
                  color: #67befa;
                  font-size: 30px;
                  margin-bottom: 20px;
                "
              >
                {{ facilityDisposeNum }}
              </p>
              <img src="/imgs/statistics/chuliliang.png" alt="" />
            </div>
          </Card>
        </Col>
        <Col span="19" push="1">
          <Card>
            <div id="myChart" style="width: 100%; height: 280px"></div>
          </Card>
        </Col>
      </Row>
    </div>
    <div style="margin-top: 30px">
      <Card>
        <Row>
          <Col span="15">
            <p style="font-size: 18px; font-weight: 600">综合统计</p>
          </Col>
          <Col span="5">
            <Select
              v-model="model1"
              style="width: 200px"
              on-change="selectChange"
            >
              <Option
                v-for="item in cityList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </Col>
          <Col span="3">
            <DatePicker
              type="daterange"
              placement="bottom-end"
              placeholder="选择日期"
              style="width: 200px"
              @on-change="getList"
            ></DatePicker>
          </Col>
        </Row>
        <Row style="margin-top: 20px">
          <Col span="24">
            <Table
              v-if="model1 == '1'"
              border
              ref="selection"
              :columns="columns"
              :data="Tabledata"
              height="200px"
            ></Table>
            <Table
              v-if="model1 == '2'"
              border
              ref="selection"
              :columns="columns2"
              :data="Tabledata"
              height="200px"
            ></Table>
          </Col>
          <!-- <Button @click="handleSelectAll(true)">全选</Button>
          <Button @click="handleSelectAll(false)">取消全选</Button> -->
        </Row>
      </Card>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      timeDate: [],
      data: [],
      disposeNum: "", //粪污处理点个数
      typeNum: "", //设备类型数
      facilityNum: {}, //设备数
      facilityDisposeNum: "", //设备处理量
      useAmountData: [],
      cityList: [
        { value: "1", label: "利用" },
        { value: "2", label: "施用" },
      ],
      model1: "1",
      Tabledata: [],
      columns: [
        // {
        //     type: 'selection',
        //     width: 60,
        //     align: 'center'
        // },
        {
          title: "集散点",
          key: "name",
        },
        {
          title: "粪污利用量(kg)",
          key: "age",
        },
        {
          title: "记录时间",
          key: "",
        },
      ],
      columns2: [
        // {
        //     type: 'selection',
        //     width: 60,
        //     align: 'center'
        // },
        {
          title: "集散点",
          key: "name",
        },
        {
          title: "粪污施用量(kg)",
          key: "address",
        },
        {
          title: "记录时间",
          key: "",
        },
      ],
    };
  },
  methods: {
    //初始化echarts
    initEcharts() {
    //   let date = new Date();
    //   let year = date.getFullYear();
    //   for (let i = 1; i <= 12; i++) {
    //     if (i < 10) {
    //       this.data.push(year + "-" + "0" + i);
    //     } else {
    //       this.data.push(year + "-" + i);
    //     }
    //   }
      let myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        title: {
          text: "粪污施用量(kg)",
        },
        xAxis: {
          type: "category",
          data: this.data,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.useAmountData,
            type: "line",
            smooth: true,
          },
        ],
      });
    },
    //获取数据list
    getList(data, type) {
      if (data[0] == "") {
        return;
      }
      {
        let startTime = data[0];
        let endTime = data[1];
        this.$post(this.$api.STATISTICS_ANALYSE.GROSS, {
          startTime: startTime,
          endTime: endTime,
        }).then((res) => {});
      }
    },

    getDispose() {
      //粪污处理点个数
      this.$post(this.$api.STATISTICS_ANALYSE.DISPOSE_NUM).then((res) => {
        this.disposeNum = res;
      });
    },

    getTypeData() {
      //设备类型数
      this.$post(this.$api.STATISTICS_ANALYSE.TYPE_NUM).then((res) => {
        this.typeNum = res;
      });
    },

    getFacility() {
      //设备类型数
      this.$post(this.$api.STATISTICS_ANALYSE.FACILITY_NUM).then((res) => {
        this.facilityNum = res;
      });
    },
    getFacilityDisposeNum() {
      //设备类型数
      this.$post(this.$api.STATISTICS_ANALYSE.FACILITY_DISPOSE_NUM).then(
        (res) => {
          this.facilityDisposeNum = res;
        }
      );
    },
    getEchartsData() {
      //粪污施用量
      this.$post(this.$api.STATISTICS_ANALYSE.USE_AMOUNT).then((res) => {
           this.data=Object.keys(res)
        let list=Object.keys(res).sort((a, b) => {
          let tempA = a.replace(/-/g, "").replace(/\s*/g, "");
          let tempB = b.replace(/-/g, "").replace(/\s*/g, "");
          return +tempA - +tempB;
        });
       for(let i = 0 ; i<list.length; i++){
           this.useAmountData.push(res[list[i]])
       }
        this.initEcharts()
      });
    },
    handleSelectAll(status) {
      this.$refs.selection.selectAll(status);
    },
    selectChange(val) {},
  },
  watch: {
    timeDate(a) {},
  },
  mounted() {
    this.getDispose();
    this.getTypeData();
    this.getFacility();
    this.getEchartsData();
    this.getFacilityDisposeNum();
    this.initEcharts();
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #ecf4f7;
  padding: 10px;
}
</style>